<template>
  <div>
    <base-modal
      :showing="visible"
      @close="$emit('close')"
      size="max-w-5xl"
      @opened="onOpened"
    >
      <base-card
        title="Produk Box QR"
        description="Daftar produk box qr"
        :with-style="false"
      >
        <div class="space-y-4 border-t py-5">
          <div class="flex justify-end">
            <base-button @click="visibleFormModal = true">
              <Icon icon="heroicons:plus" class="h-4 w-4" />
              Tambah Box
            </base-button>
          </div>
          <datatable
            :columns="tableColumns"
            :total="productBoxes.meta.page.total"
            :perPage="productBoxes.meta.page.perPage"
            :currentPage="productBoxes.meta.page.currentPage"
            @pagechanged="onChangePage"
          >
            <template #tbody="{ classes }">
              <tr
                v-for="product in productBoxes.data"
                :key="product.id"
                :class="classes.tr"
              >
                <td :class="classes.td">
                  <qr-code :text="product.attributes.qrcode" :size="50" />
                </td>
                <td :class="classes.td">{{ product.attributes.name }}</td>
              </tr>
            </template>
          </datatable>
        </div>
      </base-card>
    </base-modal>

    <base-qr-scan-modal
      :with-camera-scan="false"
      :visible="visibleFormModal"
      play-sound-on-scanned
      dynamic-qr-box
      @close="visibleFormModal = false"
      @scanned="onQrScanned"
    >
      <template #bottom>
        <form class="space-y-4" @submit.prevent="onSubmitForm">
          <base-input
            inset
            type="text"
            label="Nama Box"
            placeholder="Nama Box"
            required
            v-model="form.name"
          />
          <base-input
            inset
            type="text"
            label="Kode QR"
            placeholder="Kode QR"
            required
            v-model="form.qrcode"
          />
          <base-button fullwidth>Simpan</base-button>
        </form>
      </template>
    </base-qr-scan-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import QrCode from 'vue-qrcode-component';
import BaseQrScanModal from '@/components/base/BaseQrScanModal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    QrCode,
    BaseQrScanModal,
  },
  props: {
    visible: Boolean,
    productId: String,
  },
  emits: ['close'],
  data() {
    return {
      visibleFormModal: false,
      form: {
        name: null,
        qrcode: null,
      },
      loadingData: false,
      loadingSubmit: false,
      loaded: false,
      productBoxes: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    loading() {
      return this.loadingData || this.loadingSubmit;
    },
    tableColumns() {
      return [
        { id: 'qr', name: 'QR' },
        { id: 'name', name: 'Nama' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadProductBoxes(params) {
      this.loadingData = true;

      const [res, err] = await this.request(
        `/api/v1/products/${this.productId}/product-boxes`,
        {
          params: {
            'fields[products]': 'qrcode,name',
            'page[size]': 5,
            ...params,
          },
        }
      );

      if (!err) {
        this.productBoxes = res;

        this.loaded = true;
      }

      this.loadingData = false;
    },
    onChangePage(page) {
      this.loadProductBoxes({
        'page[number]': page,
      });
    },
    onQrScanned(code) {
      this.qrcode = code;
    },
    onOpened() {
      this.loadProductBoxes();
    },
    async onSubmitForm() {
      this.loadingSubmit = true;

      const [, err] = await this.request(`/api/v1/products`, {
        method: 'post',
        data: {
          data: {
            type: 'products',
            attributes: {
              code: this.form.name,
              name: this.form.name,
              qrcode: this.form.qrcode,
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: this.productId,
                },
              },
            },
          },
        },
      });

      if (!err) {
        this.loadProductBoxes();

        this.visibleFormModal = false;
      } else {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      }

      this.loadingSubmit = false;
    },
  },
};
</script>
